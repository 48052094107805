<template>
  <!--
    Used to edit the sound of the videoPostprocessingTheme.
  -->
  <div class="soundEdit">
    <template v-if="sound">
      <form>
        <div class="form-group">
          <h4 class="m-0 mt-3">
            {{ $t('soundEditComponent.sound') }}
          </h4>
          <hr class="m-0 mb-3">
          <label>{{ $t('name') }}</label>
          <input
            v-model="sound.name"
            v-focus
            :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
            type="text"
          >
          <span
            v-show="errors.has('Name')"
            class="badge badge-danger"
          >{{ errors.first('Name') }}</span>
          <hr class="m-0 mb-3">
          <label>{{ $t('description') }}</label>
          <input
            v-model="sound.description"
            v-focus
            :class="['form-control', { 'is-invalid': $validator.errors.has('Description') }]"
            type="text"
          >
          <hr class="m-0 mb-3">
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2">{{ $t('active') }}</label>
            <div class="col-12 col-md-10">
              <toggle-button
                v-model="sound.active"
                :width="50"
                :height="25"
                class="m-0"
              />
            </div>
          </div>
          <span
            v-show="errors.has('Description')"
            class="badge badge-danger"
          >{{ errors.first('Description') }}</span>
        </div>
        <button
          class="btn btn-sm btn-primary float-right"
          @click.prevent="updateSound()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="check"
          />{{ $t('save') }}
        </button>
        <div class="clearfix" />
      </form>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "SoundEdit",
  mixins: [
    errorMixin
  ],
  props: {
    sound: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: true
    }
  },
  methods: {
    updateSound () {
      let soundDto = {
        'id': this.sound.id,
        'name': this.sound.name,
        'description': this.sound.description,
        'sourceName': this.sound.sourceName,
        'active': this.sound.active
      };
      this.axios.put('/VideoPostProcessing/UpdateSoundElement', soundDto)
        .then(() => {
          this.$emit("reload");
          this.error_clear();
          this.$snotify.success(this.$t('soundEditComponent.updatedSuccessfully'));
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>
