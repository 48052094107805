import { render, staticRenderFns } from "./SoundsEdit.vue?vue&type=template&id=a91fa73c&"
import script from "./SoundsEdit.vue?vue&type=script&lang=js&"
export * from "./SoundsEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports